import { QueryClientImpl } from "cosmjs-types/cosmos/bank/v1beta1/query";
import {
    createProtobufRpcClient,
    QueryClient,
} from "@cosmjs/stargate";
import { HttpBatchClient, Tendermint34Client } from "@cosmjs/tendermint-rpc";
import { comdex } from "../config";


const newQueryClientRPC = (rpc, callback) => {
    const httpBatch = new HttpBatchClient(rpc, {
        batchSizeLimit: 50,
        dispatchInterval: 500,
    });
    Tendermint34Client.create(httpBatch)
        .then((tendermintClient) => {
            const queryClient = new QueryClient(tendermintClient);
            const rpcClient = createProtobufRpcClient(queryClient);
            callback(null, rpcClient);
        })
        .catch((error) => {
            callback(error?.message);
        });
};

export const queryAllBalances = (owner, callback) => {
    newQueryClientRPC(comdex?.rpc, (error, client) => {
        if (error) {
            callback(error);
            return;
        }

        const stakingQueryService = new QueryClientImpl(client);

        stakingQueryService
            .AllBalances({
                address: owner,
            })
            .then((result) => {
                callback(null, result);
            })
            .catch((error) => {
                callback(error?.message);
            });
    });
};