
import { MsgSend } from "cosmjs-types/cosmos/bank/v1beta1/tx";
import {
    SigningStargateClient
} from "@cosmjs/stargate";
import { comdex } from "../config";

const trimWhiteSpaces = (data) => data.split(" ").join("");

export function Fee(amount, gas = 250000, network) {
    return {
        amount: [{ amount: String(amount), denom: network?.coinMinimalDenom }],
        gas: String(gas),
    };
}


export const MsgSendTokens = (
    userAddress,
    receiverAddress,
    currentChain,
    amount
) => {
    return {
        typeUrl: "/cosmos.bank.v1beta1.MsgSend",
        value: MsgSend.fromPartial({
            fromAddress: trimWhiteSpaces(userAddress),
            toAddress: trimWhiteSpaces(receiverAddress),
            amount: [
                {
                    denom: currentChain?.coinMinimalDenom,
                    amount: String(amount),
                },
            ],
        }),
    };
};


const KeplrWallet = async (chainID = comdex.chainId) => {
    await window.keplr.enable(chainID)

    const offlineSigner = await window.getOfflineSignerAuto(chainID)

    const accounts = await offlineSigner.getAccounts();
    return [offlineSigner, accounts];

};



export const TransactionWithKeplr = async (
    transaction,
    address,
    currentChain,
    callback
) => {
    const [offlineSigner, accounts] = await KeplrWallet(currentChain?.chainId);
    if (address !== accounts[0].address) {
        const error = "Connected account is not active in Keplr";
        callback(error);
        return;
    }

    SigningStargateClient.connectWithSigner(currentChain.rpc, offlineSigner, {})
        .then((client) => {
            client
                .signAndBroadcast(
                    address,
                    [transaction.message],
                    transaction.fee,
                    transaction.memo
                )
                .then((result) => {
                    callback(null, result);
                })
                .catch((error) => {
                    callback(error?.message);
                });
        })
        .catch((error) => {
            callback(error && error.message);
        });
};