import logo from './logo.svg';
import './App.css';
import { comdex, suggestChainConfig } from './config';
import { useEffect, useState } from 'react';
import { queryAllBalances } from './services/bankQuery';
import { amountConversion } from './common';
import { Fee, MsgSendTokens, TransactionWithKeplr } from './services/bankSend';
import comdexLogo from './Comdex_Logo 2.svg';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

function App() {

  const [address, setAddress] = useState("");
  const [balance, setBalance] = useState('')
  const [amount, setAmount] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("")
  const [loading, setLoading] = useState(false)
  const [refreshBalance, setRefereceBalance] = useState(1);
  const [maxclicked, setMaxClicked] = useState(false)


  window.addEventListener("keplr_keystorechange", () => {
    handleKeplrConnect();
  });

  const handleKeplrConnect = async () => {
    if (!window.keplr) {
      window.alert("Please install keplr extension")
    } else {
      window.keplr.experimentalSuggestChain(suggestChainConfig)
      const offlineSigner = window.getOfflineSigner(comdex?.chainId)

      const accounts = await offlineSigner.getAccounts()

      setAddress(accounts?.[0]?.address)
    }
  }

  const fetchBalance = (address) => {
    queryAllBalances(address, (error, result) => {
      if (error) {
        console.log(error, "Error in Balance function");
      }

      let cmdxBalance = 0
      for (const item of result?.balances) {
        if (item.denom === 'ucmdx') {
          cmdxBalance = item.amount
          break; // Exit the loop once the desired item is found
        }
      }
      setBalance(amountConversion(cmdxBalance, comdex?.coinDecimals))
    })
  }

  useEffect(() => {
    if (address) {
      fetchBalance(address)
    }
  }, [address, refreshBalance])


  const handleClickTx = () => {
    setLoading(true)
    toast("Transaction Initiated")
    let msg = MsgSendTokens(
      address,
      receiverAddress,
      comdex,
      maxclicked ? Number(amount * (10 ** 6)) - (0.1 * 10 ** 6) : Number(amount * (10 ** 6))
    );
    TransactionWithKeplr(
      {
        message: msg,
        fee: Fee(0, 250000, comdex),
        memo: "",
      },
      address,
      comdex,
      (error, result) => {
        if (error) {
          console.log(error, "error");
          toast("Transaction Failed")
          setLoading(false)
          return;
        }
        if (result && !result?.code) {
          console.log("Msg Success");
          toast("Transaction Successfull")
          setRefereceBalance(refreshBalance + 1)
          setAmount(0)
          setLoading(false)
        } else {
          console.log(result?.rawLog, "Error rawlog");
          toast("Transaction Failed")
          setLoading(false)
        }

      }
    );
  }


  const handleMaxClick = () => {
    setMaxClicked(!maxclicked)
    setAmount(balance)
  }


  return (
    <div className="App">
      <ToastContainer theme='dark' />
      <div className="App-header">

        <div className="navbar_main_container">
          <div className="navbar_container">
            <div className="logo">
              <img src={comdexLogo} alt="Logo" />
            </div>
            <div className="button_container">
              {address ?
                <button className='button' onClick={() => setAddress("")}> Disconnect</button>
                :
                <button className='button' onClick={handleKeplrConnect}> Connect Wallet</button>
              }
            </div>
          </div>
        </div>

        <div className="form_main_container">
          <div className="form_container">


            <div className="input_field_container">
              <div className="from_address send_address">
                <label> Sender Address : </label>
                <input disabled type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>
              <div className="send_address">
                <label > Receiver Address : </label>
                <input type="text" value={receiverAddress} onChange={(e) => setReceiverAddress(e.target.value)} />
              </div>
              <div className="keplr_wallet_container">

                <>
                  <div> {address && balance || 0} CMDX</div>
                  <button onClick={handleMaxClick} className='max_button'>MAX</button>

                </>

              </div>
              <div className="send_address">
                <label> Amount : </label>
                <input type="number" value={amount} onChange={(e) => setAmount(e?.target?.value)} />
              </div>
              <button
                onClick={handleClickTx}
                className='button'
                disabled={
                  loading ||
                  !balance ||
                  !amount
                }
              >Send</button>
            </div>

          </div>
        </div>

      </div >
    </div>
  );
}

export default App;
