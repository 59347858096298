export const comdex = {
    rpc: "https://comdex-dapps-rpc.zenscape.one/rpc",
    rest: "https://comdex-dapps-rest.zenscape.one/rest",
    chainId: "comdex-1",
    coinDenom: "CMDX",
    coinMinimalDenom: "ucmdx",
    coinDecimals: 6,
    prefix: "comdex",
    coinType: 118,
    chainName: "Comdex",
    explorerUrlToTx: "https://www.mintscan.io/comdex/txs/{txHash}",
    apiUrl: "https://stat.comdex.one",
    comdexStakingUrl: "https://comdex.omniflix.co/stake",
    webSocketApiUrl: "wss://rpc.comdex.one/websocket",
    symbol: "CMDX",
}

export const suggestChainConfig = {
    rpc: comdex?.rpc,
    rest: comdex?.rest,
    chainId: comdex?.chainId,
    chainName: comdex?.chainName,
    stakeCurrency: {
        coinDenom: comdex?.coinDenom,
        coinMinimalDenom: comdex?.coinMinimalDenom,
        coinDecimals: comdex?.coinDecimals,
    },
    bip44: {
        coinType: comdex?.coinType,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${comdex?.prefix}`,
        bech32PrefixAccPub: `${comdex?.prefix}pub`,
        bech32PrefixValAddr: `${comdex?.prefix}valoper`,
        bech32PrefixValPub: `${comdex?.prefix}valoperpub`,
        bech32PrefixConsAddr: `${comdex?.prefix}valcons`,
        bech32PrefixConsPub: `${comdex?.prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom: comdex?.coinDenom,
            coinMinimalDenom: comdex?.coinMinimalDenom,
            coinDecimals: comdex?.coinDecimals,
        }
    ],
    feeCurrencies: [{
        coinDenom: comdex?.coinDenom,
        coinMinimalDenom: comdex?.coinMinimalDenom,
        coinDecimals: comdex?.coinDecimals,
        gasPriceStep: {
            low: 0.01,
            average: 0.025,
            high: 0.04
        }
    }]
}